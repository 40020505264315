
import { Vue, Component } from 'vue-property-decorator'
import Echarts from 'echarts'
import { CostProportionDetail, CostAnalysisDetail, ItemAnalysisDetail, CompareDetail } from '../../types/operationMonthReport'

@Component
export default class MonthReport extends Vue {
  private projectName = ''
  private yearMonth = ''
  // 图表 成本占比分析
  private costProportion: any = null
  // 图表 人机材成本分析趋势图
  private costAnalysis: any = null
  // 图表 人力成本分析 班组成本占比分析、每日上工人数与人力成本对比分析、班组加班情况分析
  private personCost1: any = null
  private personCost2: any = null
  private personCost3: any = null
  // 图表 材料成本分析 材料供应商成本占比分析、各材料使用数量及成本统计分析
  private materialCost1: any = null
  private materialCost2: any = null
  // 图表 机械成本分析 机械类型占比分析、每日机械成本统计分析
  private machineryCost1: any = null
  private machineryCost2: any = null

  get searchInfo () {
    return this.$route.params || ''
  }

  created () {
    this.getData()
  }

  mounted () {
    window.addEventListener('resize', this.changeResize)
  }

  destroyed () {
    window.removeEventListener('resize', this.changeResize)
  }

  changeResize () {
    this.costProportion && this.costProportion.resize()
    this.costAnalysis && this.costAnalysis.resize()
    this.personCost1 && this.personCost1.resize()
    this.personCost2 && this.personCost2.resize()
    this.personCost3 && this.personCost3.resize()
    this.materialCost1 && this.materialCost1.resize()
    this.materialCost2 && this.materialCost2.resize()
    this.machineryCost1 && this.machineryCost1.resize()
    this.machineryCost2 && this.machineryCost2.resize()
  }

  getData () {
    this.$axios.get(this.$apis.operationmanage.selectOperatingReportByDate, this.searchInfo).then(res => {
      this.projectName = res.projectName || ''
      this.yearMonth = res.yearMonth || ''
      // 成本占比分析
      res.costProportion && this.drawCostProportion(res.costProportion)
      // 人机材成本分析趋势图
      if (res.manMachineMaterialCost && res.manMachineMaterialCost.length > 0) {
        this.drawAllCostAnalysis(res.manMachineMaterialCost)
      }
      if (res.proportionTeamCost && res.proportionTeamCost.length > 0) {
        this.drawCostAnalysis('班组成本占比分析', 'personCost1', res.proportionTeamCost)
      }
      if (res.proportionMaterialSupplierCost && res.proportionMaterialSupplierCost.length > 0) {
        this.drawCostAnalysis('材料供应商成本占比分析', 'materialCost1', res.proportionMaterialSupplierCost)
      }
      if (res.proportionMachineryType && res.proportionMachineryType.length > 0) {
        this.drawCostAnalysis('机械类型占比分析', 'machineryCost1', res.proportionMachineryType)
      }
      if (res.comparisonBetweenDailyNumberWorkersLaborCost && res.comparisonBetweenDailyNumberWorkersLaborCost.length > 0) {
        this.drawCompareAnalysis('每日上工人数与人力成本对比分析', ['上工人数', '人力成本'], 'personCost2', res.comparisonBetweenDailyNumberWorkersLaborCost)
      }
      if (res.overtimeTeam && res.overtimeTeam.length > 0) {
        this.drawCompareAnalysis('班组加班情况分析', ['加班时长', '加班费用'], 'personCost3', res.overtimeTeam)
      }
      if (res.quantityCostStatisticsEachMaterial && res.quantityCostStatisticsEachMaterial.length > 0) {
        this.drawCompareAnalysis('各材料使用数量及成本统计', ['数量', '成本'], 'materialCost2', res.quantityCostStatisticsEachMaterial)
      }
      // 每日机械成本统计分析 dailyMachineryCostStatistics
      if (res.dailyMachineryCostStatistics && res.dailyMachineryCostStatistics.length >= 0) {
        this.drawMachineryCostAnalysis(res.dailyMachineryCostStatistics)
      }
    })
  }

  // 成本占比分析
  drawCostProportion (obj: CostProportionDetail) {
    this.$nextTick(() => {
      this.costProportion && this.costProportion.clear()
      this.costProportion = Echarts.init(this.$refs.costProportion as any)
      const data = [{
        name: '人力成本合计',
        value: obj.userTotal || '0.00',
        percent: '0.00'
      }, {
        name: '机械成本合计',
        value: obj.machineryTotal || '0.00',
        percent: '0.00'
      }, {
        name: '材料成本合计',
        value: obj.materialTotal || '0.00',
        percent: '0.00'
      }]
      const allTotal = Number(obj.allTotal)
      if (allTotal > 0) {
        data.forEach(item => {
          item.percent = (Number(item.value) * 100 / allTotal).toFixed(2)
        })
        const total = data.reduce((total, val) => {
          return total + Number(val.percent)
        }, 0)
        data[0].percent = (100 - total + Number(data[0].percent)).toFixed(2)
      }
      const options: any = {
        tooltip: {
          show: true,
          formatter: (data: any) => {
            return data.name + ':&nbsp;' + data.percent + '%'
          }
        },
        legend: {
          orient: 'vertical',
          left: '50%',
          top: 'center',
          icon: 'circle',
          itemWidth: 8,
          itemGap: 8,
          formatter: (name: string) => {
            const ind = data.findIndex(item => item.name === name)
            let txt = '{a|' + name + '}'
            if (ind >= 0) {
              txt += '{b| |  ' + data[ind].percent + '%}{c|￥' + Number(data[ind].value).toLocaleString('zh', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '}'
            }
            if (name === '人力成本合计') {
              txt += '\n{d|' + '(其中加班费合计 ￥' + Number(obj.userOvertimeTotal || 0).toLocaleString('zh', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ')}'
            }
            return txt
          },
          textStyle: {
            fontSize: 14,
            color: '#000000',
            lineHeight: 22,
            rich: {
              a: {
                color: '#666666',
                padding: [0, 8, 0, 8]
              },
              b: {
                color: '#999999',
                width: 80
              },
              c: {
                color: '#000000'
              },
              d: {
                color: '#666666',
                fontSize: 12,
                align: 'left',
                padding: [9, 8, 0, 8]
              }
            }
          }
        },
        color: ['#2C8EFC', '#2FC25A', '#FFC117'],
        series: [
          {
            name: '',
            // avoidLabelOverlap: false,
            hoverOffset: 0,
            type: 'pie',
            radius: ['60%', '80%'],
            center: ['25%', '50%'],
            itemStyle: {
              borderWidth: 3, // 设置border的宽度有多大
              borderColor: '#fff'
            },
            label: {
              show: true,
              formatter: () => {
                return '{a|总成本合计}\n' + '{b|' + '￥' + Number(obj.allTotal).toLocaleString('zh', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '}'
              },
              position: 'center',
              fontFamily: 'PingFangSC-Regular, PingFang SC',
              rich: {
                a: {
                  fontSize: 14,
                  fontWeight: 400,
                  color: '#999999',
                  lineHeight: 22,
                  padding: [0, 0, 2, 0]
                },
                b: {
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: 22,
                  color: '#333333'
                }
              }
            },
            data: data,
            labelLine: {
              show: false
            }
          }
        ]
      }
      this.costProportion && this.costProportion.setOption(options)
    })
  }

  // 人机材成本分析趋势图
  drawAllCostAnalysis (arr: Array<CostAnalysisDetail>) {
    this.$nextTick(() => {
      this.costAnalysis && this.costAnalysis.clear()
      this.costAnalysis = Echarts.init(this.$refs.costAnalysis as any)
      const xData: string[] = []
      const yData1: string[] = []
      const yData2: string[] = []
      const yData3: string[] = []
      arr.forEach(item => {
        xData.push(item.dateStr || '')
        yData1.push(item.userTotal || '')
        yData2.push(item.machineryTotal || '')
        yData3.push(item.materialTotal || '')
      })
      const option: any = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: 60,
          left: 24,
          right: 24,
          bottom: 24,
          containLabel: true
        },
        legend: {
          icon: 'rect',
          top: 24,
          left: 24,
          itemWidth: 16,
          itemHeight: 1,
          itemGap: 16,
          textStyle: {
            color: '#8C8C8C',
            lineHeight: 18
          }
        },
        backgroundColor: 'transparent',
        color: ['#2C8EFF', '#2FC25A', '#FFC117'],
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: '#999999'
            }
          },
          data: xData
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: '人力成本',
          type: 'line',
          symbolSize: 0,
          smooth: true,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },
          lineStyle: {
            width: 4
          },
          data: yData1
        }, {
          name: '机械成本',
          type: 'line',
          symbolSize: 0,
          smooth: true,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },
          lineStyle: {
            width: 4
          },
          data: yData2
        }, {
          name: '材料成本',
          type: 'line',
          symbolSize: 0,
          smooth: true,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },
          lineStyle: {
            width: 4
          },
          data: yData3
        }]
      }
      this.costAnalysis.setOption(option)
    })
  }

  // 饼图 人、机、材占比分析
  drawCostAnalysis (name: string, chartName: 'personCost1' | 'materialCost1' | 'machineryCost1', arr: Array<ItemAnalysisDetail>) {
    this.$nextTick(() => {
      this[chartName] && this[chartName].clear()
      this[chartName] = Echarts.init(this.$refs[chartName] as any)
      const data: Array<{ name: string; value: string }> = []
      // 最多展示6个，取前六个
      arr.slice(0, 6).forEach(item => {
        data.push({
          name: item.name || item.supplierName || '',
          value: item.userTotal || item.machineryTotal || item.materialTotal || ''
        })
      })
      // 如果大于6个则把第6个换成其他
      if (arr.length > 6) {
        const total = arr.slice(6).reduce((total, item) => total + Number(item.userTotal || item.machineryTotal || item.materialTotal || ''), 0)
        data[5] = {
          name: '其他',
          value: (total).toFixed(2)
        }
      }
      const color = ['#00A4FF', '#36CBCB', '#4DCB73', '#FFD300', '#FF587A', '#975FE4']
      const options = {
        tooltip: {
          show: true,
          formatter: '{a} <br/>{b}: {d}%'
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          left: '72%',
          icon: 'circle',
          itemWidth: 8,
          formatter: (name: string) => {
            return name.length > 4 ? name.substring(0, 4) + '...' : name
          }
        },
        color: color,
        title: {
          text: name,
          top: 30,
          left: '38%',
          textAlign: 'center',
          textStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: '#666666'
          }
        },
        series: [
          {
            name: name,
            // avoidLabelOverlap: false,
            hoverOffset: 0,
            type: 'pie',
            radius: '54%',
            center: ['38%', '58%'],
            label: {
              show: false,
              position: 'center'
            },
            data: data,
            labelLine: {
              show: false
            }
          }, {
            name: name,
            labelLine: {
              show: false
            },
            itemStyle: {
              opacity: 0.2,
              borderColor: '#FFFFFF',
              borderWidth: 0
            },
            type: 'pie',
            hoverAnimation: false,
            radius: ['54%', '64%'],
            center: ['38%', '58%'],
            label: {
              normal: {
                show: false
              }
            },
            data: data,
            zlevel: -1
          }
        ]
      }
      this[chartName].setOption(options)
    })
  }

  // 每日上工人数与人力成本对比分析、班组加班情况分析、各材料使用数量及成本统计
  drawCompareAnalysis (name: string, legend: string[], chartName: 'personCost2' | 'personCost3' | 'materialCost2', arr: Array<CompareDetail>) {
    const xData: string[] = []
    const yData1: string[] = []
    const yData2: string[] = []
    arr.forEach(item => {
      xData.push(item.dateStr || item.name || '')
      yData1.push(item.userQuantity || item.userOvertimeHours || item.materialQuantity || '0.00')
      yData2.push(item.userTotal || item.userOvertimeTotal || item.materialTotal || '0.00')
    })
    const splitNumber = 5
    const y1Max = yData1.reduce((max: number, item: string) => {
      return Number(item) > max ? Number(item) : max
    }, 0) + 10
    const y1Min = 0
    const y2Max = yData2.reduce((max: number, item: string) => {
      return Number(item) > max ? Number(item) : max
    }, 0) + 10
    const y2Min = 0
    const series: object[] = [{
      name: legend[0],
      type: 'bar',
      emphasis: {
        focus: 'series'
      },
      data: yData1,
      color: '#5B8FF9',
      barWidth: 20
    }, {
      name: legend[1] || '',
      type: 'line',
      symbolSize: 2,
      yAxisIndex: 1,
      smooth: true,
      itemStyle: {
        normal: {
          borderWidth: 2,
          color: '#36CBCB'
        }
      },
      lineStyle: {
        width: 4
      },
      data: yData2
    }]
    if (chartName === 'personCost3') {
      series[1] = {
        name: legend[1],
        type: 'bar',
        yAxisIndex: 1,
        emphasis: {
          focus: 'series'
        },
        data: yData2,
        color: '#36CBCB',
        barWidth: 20
      }
    }
    this.$nextTick(() => {
      this[chartName] && this[chartName].clear()
      this[chartName] = Echarts.init(this.$refs[chartName] as any)
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        title: {
          text: name,
          top: 30,
          left: '50%',
          textAlign: 'center',
          textStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: '#666666'
          }
        },
        legend: {
          data: legend,
          top: 60,
          itemHeight: 1,
          itemWidth: 20,
          textStyle: {
            color: '#8C8C8C',
            fontSize: 12,
            padding: [0, 10, 0, 0]
          }
        },
        grid: {
          top: 100,
          left: 24,
          right: 24,
          bottom: 24,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: xData,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            formatter: (value: string) => {
              if (value.length > 8) {
                return `${value.substring(0, 8)}...`
              } else {
                return value
              }
            },
            fontSize: 12,
            color: 'rgba(0, 0, 0, 0.45)'
          },
          splitLine: {
            show: false
          },
          min: 0
        },
        yAxis: [{
          type: 'value',
          splitNumber: splitNumber,
          min: y1Min,
          max: y1Max,
          interval: Number(((y1Max - y1Min) / splitNumber).toFixed(2)),
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 12,
            color: 'rgba(0, 0, 0, 0.45)'
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        }, {
          type: 'value',
          splitNumber: splitNumber,
          min: y2Min,
          max: y2Max,
          interval: Number(((y2Max - y2Min) / splitNumber).toFixed(2)),
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          },
          axisLabel: {
            show: true,
            fontSize: 12,
            color: 'rgba(0, 0, 0, 0.45)',
            formatter: '{value}元'
          }
        }],
        series: series
      }
      this[chartName].setOption(options)
    })
  }

  // 每日机械成本统计分析
  drawMachineryCostAnalysis (arr: Array<{ dateStr: string; machineryTotal: string }>) {
    this.$nextTick(() => {
      this.machineryCost2 && this.machineryCost2.clear()
      this.machineryCost2 = Echarts.init(this.$refs.machineryCost2 as any)
      const xData: string[] = []
      const yData: string[] = []
      arr.forEach(item => {
        xData.push(item.dateStr || '')
        yData.push(item.machineryTotal || '')
      })
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: 80,
          left: 24,
          right: 24,
          bottom: 24,
          containLabel: true
        },
        title: {
          text: '每日机械成本统计分析',
          top: 30,
          left: '50%',
          textAlign: 'center',
          textStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: '#666666'
          }
        },
        backgroundColor: 'transparent',
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: '#999999'
            }
          },
          data: xData
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: '机械成本',
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          data: yData,
          color: '#5B8FF9',
          barWidth: 20
        }]
      }
      this.machineryCost2.setOption(options)
    })
  }

  // 导出
  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '导出中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.operationmanage.exportOperatingReport, this.searchInfo).finally(() => {
      loading.close()
    })
  }
}
